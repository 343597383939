/*
 *
 *   GULL - Angular Bootstrap Admin Template
 *
 *
*/

@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'ngx-toastr/toastr';
@import "angular-calendar/scss/angular-calendar.scss";
@import 'highlight.js/styles/github.css';
@import '../iconsmind/iconsmind.css';
@import 'variables';

//@import "themes/lite-blue";
@import 'themes/themes';
@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap-rtl.scss";
@import "globals/globals";